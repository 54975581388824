.gradient-bg {
  background: radial-gradient(ellipse at top left, white 50%, grey 100%);
}



  .welcome {
    height: 10vh;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
  }

  .welcometext {
    font-family: "pokemon";
    margin-top: 150px;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  .nav-link-bounce:hover{
    animation: bounce 1s infinite;
  }

  .bouncing-text {
    display: inline-block;
    font-size: 50px;
    animation: bounce 1s infinite;
  }

  .aboutme1 {
    height: 25vh;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
    font-family: "pokemon";
    
  }

  .aboutmesegment {
    height: 25vh;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
    font-family: "pokemon";

  }

  .portfolio {
    height: 25vh;
    display: flex;
    justify-content: center; 
    align-items: center; 
    font-family: "pokemon";
  }

  .portfoliocontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    text-align: center;
  }

  .hobbiescontent {
    height: 25vh;
    display: flex;
    justify-content: center; 
    align-items: center; 
    font-family: "pokemon";
  }

  
  .contact1 {
    height: 30vh;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
    font-family: "pokemon";
  }

  .contacticon {
    height: 30vh;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
    font-family: "pokemon";
  }

  @font-face {
    font-family: "pokemon";
    src: url('../public/pokemonfont/PokemonGb-RAeo.ttf') format('truetype');
  }

  .navbarbackground {
    background-color: whitesmoke;
    font-family: "pokemon";
    font-size:12px;
    height: 5vh;
  }

.navbarname {
  font-size: 15px;
  margin-right: 16px;
}


@font-face {
  font-family: "pokemonpixel";
  src: url('../public/pokemonfont/PokemonPixels1-jYmj.ttf') format('truetype');
}
.pokemonpixel {
  font-family: "pokemonpixel";
  animation: bounce 1s infinite;
  font-size: 50px;
  margin-right: 20px;
  margin-top: 150px;
}

.pokemonpixel3 {
  font-family: "pokemonpixel";
  /* animation: bounce 1s infinite; */
  font-size: 25px;
  
}

.pokemonpixel2 {
  font-family: "pokemonpixel";
  animation: bounce 1s infinite;
  font-size: 50px;
  margin-left: 20px;
  margin-top: 150px;
}
.navlinkpokemon {
  font-family: "pokemonpixel";
  font-size: 30px;
}

/* Technical SKills css */
.technicalskilltext {
  font-family: "pokemon";
  font-size: 15px;
}

.technicalskillcontent {
  font-family: "pokemon";
  font-size: 13px;
  margin-top: 15px;
  text-align: center;
}

.technicalborder {
  border:4px solid black;
}

/* .technicalborder {
  border: 2px solid black;
  margin-bottom: 10px;
} */
.technicalborder {
  border: 2px solid black; /* Add a border */
  padding: 10px; /* Optional: Add padding inside the div */
  /* Remove the width property */
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: center;
  font-family: pokemon;
  font-size: 10px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.techincalbox {
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
}

.boxjump {
  transition: transform 0.5s ease-out;
    display: inline-block;
}

.boxjump:hover {
  transform: scale(1.05)
}

/* Contact me Section */

.contactbox {
  height: 25vh;
 
}

.pokemonpixelC {
  font-family: "pokemonpixel";
  font-size: 20px;
}

.pokemonpixelC:hover{
  animation: bounce 1s infinite;
}
