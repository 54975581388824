.slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 600px;
    margin: auto;
  }
  
  .image-container {
    flex: 1;
    position: relative;
    text-align: center;
  }
  
  .image-container a {
    display: inline-block; /* Ensure the <a> tag surrounds the image */
  }
  
  .image-container img {
    width: 400px; 
    height: auto; 
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .animating img {
    opacity: 0;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none; /* Prevent the overlay from intercepting clicks */
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }
  
  .overlay-text {
    text-align: center;
  }
  
  .overlay-text .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .overlay-text .description {
    font-size: 1rem;
  }
  
  .arrow-icon {
    cursor: pointer;
    font-size: 24px;
    user-select: none;
  }
  
  .left-arrow {
    margin-right: 10px;
  }
  
  .right-arrow {
    margin-left: 10px;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .bounce {
    animation: bounce 0.5s;
  }